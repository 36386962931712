<template>
	<div v-if="me && users?.length" class="comment-list">
		<CommentAction
			:is-view-only="isViewOnly"
			class="mb-2"
			v-for="comment in commentsList"
			:key="comment?.id"
			:comment="comment"
			:users="users"
			:me="me"
			:light-box-view="lightBoxView"
			@edit-comment="editComment"
			@update-comment="updateComment"
			@toggle-reply-box="toggleReplyBox"
			@delete-comment="deleteComment" />
		<div v-if="allowMore && !expandedComment" class="d-flex justify-center">
			<v-btn large rounded color="#fff" class="border-btn mb-1" width="11rem" @click="loadMoreComments"
				>Load more</v-btn
			>
		</div>
	</div>
</template>

<script>
	import CommentAction from "./CommentAction.vue";
	import api from "@/api";
	export default {
		components: {
			CommentAction,
		},
		name: "CommentsList",
		props: {
			comments: {
				type: Array,
				required: true,
			},
			isViewOnly: {
				type: Boolean,
				required: true,
			},
			lightBoxView: {
				type: Boolean,
				required: true,
			},
			allowMore: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				expandedComment: null,
				me: null,
				users: [],
			};
		},
		async mounted() {
			this.me = await api.getMe(true, true, true);
			const users = await api.getUsers({ pageSize: 1000 });
			this.users = users.rows;
		},
		methods: {
			loadMoreComments() {
				this.$emit("load-more-comments");
			},
			editComment(comment, isEditing) {
				this.$emit("edit-comment", comment, isEditing);
			},
			updateComment(comment) {
				this.$emit("update-comment", comment);
			},
			toggleReplyBox(isOpen, commentId) {
				console.log("toggleReplyBoxcheckbool", isOpen, commentId);
				if (isOpen) {
					this.expandedComment = commentId;
				} else {
					this.expandedComment = null;
				}
				this.$emit("toggle-reply-box", isOpen, commentId);
			},
			deleteComment(comment) {
				this.$emit("delete-comment", comment);
			},
		},
		computed: {
			commentsList() {
				if (this.expandedComment) {
					return [this.comments.find((comment) => comment?.id === this.expandedComment)];
				}
				return this.comments.filter((comment) => comment.deletedAt === null || comment.replyCount > 0);
			},
		},
		watch: {
			comments: {
				handler() {},
				deep: true,
			},
		},
	};
</script>
