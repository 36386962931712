<template>
	<div
		v-if="this.$vuetify.breakpoint.smAndDown || lightBoxView"
		class="d-flex flex-column justify-center align-center mb-6 mt-10 no-comment">
		<v-icon size="100" color="black">icon-chat-stroke</v-icon>
		<div v-if="!isViewOnly" class="d-flex justify-center">Leave the first comment.</div>
		<div class="text-h6 text-center" v-else>
			<div class="d-flex justify-center pb-4">Looks like you aren't signed in.</div>
			<div class="d-flex justify-center">Sign in to view others' comments or leave your own.</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			isViewOnly: {
				required: true,
				default: false,
			},
			lightBoxView: {
				required: true,
				default: false,
			},
		},
	};
</script>
